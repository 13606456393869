const workPlaces = [
  {
    title: 'As Full Stack Developer & Team Lead',
    description: 'This page that youre watching right now',
    type: 'IDF',
    images: [],
    startDate: '08/2020',
    endDate: 'curr',
  },
];

export { workPlaces };
